import React from "react"
import "../styles/global.css";
import Layout from "../components/layout"

export default function Home() {
    return <Layout>
        <div class="contact-wrapper">
            <div class="content">
                <h2>Contact Us</h2>
                <div>
                    Flagstaff Hill, SA 5159<br />
                    <abbr title="Phone">Ph:</abbr>
                    <a href="tel:0883873833">(08) 8387 3833</a>
                    <br />
                    <abbr title="Mobile">Mob:</abbr>
                    <a href="tel:0419815335">0419 815 335</a>
                    <br />
                    <abbr title="Email">Email:</abbr>
                    <a href="mailto:admin@dgplumbing.com.au">admin@dgplumbing.com.au</a>
                </div>
                <h2>Office Hours</h2>
                <div>
                    <strong>Weekdays:</strong>  8AM - 4:30PM<br />
                    <strong>Emergencies, After Hours, Weekends:</strong>  By appointment (please contact office)<br />
                </div>

                <h2>Or Find Us On Facebook</h2>
                <div class="fb-page fb_iframe_widget" data-href="https://www.facebook.com/DG-Plumbing-387136877993082/" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true" fb-xfbml-state="rendered" fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=1267&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FDG-Plumbing-387136877993082%2F&amp;locale=en_GB&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false">
                    <span style={{ verticalAlign: "bottom", width: "340px", height: "181px" }}><iframe name="f3aa3b918fe952c" width="1000px" height="1000px" data-testid="fb:page Facebook Social Plugin" title="fb:page Facebook Social Plugin" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v2.8/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df3b050380a4a6c8%26domain%3Dwww.dgplumbing.com.au%26origin%3Dhttp%253A%252F%252Fwww.dgplumbing.com.au%252Ffdd9eb6f077488%26relation%3Dparent.parent&amp;container_width=1267&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FDG-Plumbing-387136877993082%2F&amp;locale=en_GB&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false" style={{ border: "none", visibility: "visible", width: "340px", height: "181px" }} class=""></iframe></span></div>

            </div>
        </div>
    </Layout >
}
